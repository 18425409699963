import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    AppBar,
    Box,
    Button,
    Container,
    Menu,
    MenuItem,
    Typography,
    Fade,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Collapse,
    useTheme,
    useMediaQuery
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Logo from 'components/Logo';
import { NavbarProps } from 'constants/interface';

const Navbar: React.FC<NavbarProps> = ({ homeRef, aboutRef, contactUsRef }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [servicesAnchorEl, setServicesAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [servicesOpen, setServicesOpen] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();
    const navigate = useNavigate();

    const services = [
        {
            title: 'Custom Software Development',
            path: 'custom-software',
            description: 'Tailored solutions for your unique business needs'
        },
        {
            title: 'Web Development',
            path: 'web-development',
            description: 'Modern, responsive, and scalable web applications'
        },
        {
            title: 'Mobile Development',
            path: 'mobile-development',
            description: 'Native and cross-platform mobile applications'
        },
        {
            title: 'Cloud Solutions',
            path: 'cloud-solutions',
            description: 'Scalable cloud infrastructure and services'
        },
        {
            title: 'AI & Machine Learning',
            path: 'ai-ml',
            description: 'Intelligent solutions powered by AI and ML'
        }
    ];

    useEffect(() => {
        const handleScroll = () => setIsScrolled(window.scrollY > 50);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleNavClick = async (ref: React.RefObject<HTMLDivElement>) => {
        if (location.pathname !== '/') {
            await navigate('/');
            setTimeout(() => {
                ref?.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        } else {
            ref?.current?.scrollIntoView({ behavior: 'smooth' });
        }
        setMobileOpen(false);
    };

    const mobileDrawer = (
        <Box sx={{ width: 280 }}>
            <Box sx={{ p: 2 }}>
                <Logo />
            </Box>
            <List>
                <ListItem onClick={() => handleNavClick(homeRef)}>
                    <ListItemText primary="Home" />
                </ListItem>

                <ListItem onClick={() => setServicesOpen(!servicesOpen)}>
                    <ListItemText primary="Services" />
                    {servicesOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={servicesOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {services.map((service) => (
                            <ListItem
                                key={service.path}
                                component={Link}
                                to={`/services/${service.path}`}
                                onClick={() => setTimeout(() => {
                                    setMobileOpen(false)
                                }, 200)}
                                sx={{ pl: 4 }}
                            >
                                <ListItemText
                                    primary={service.title}
                                    secondary={service.description}
                                    primaryTypographyProps={{ color: 'primary' }}
                                    secondaryTypographyProps={{ variant: 'caption' }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Collapse>

                <ListItem component={Link} to="/blogs" onClick={() => setTimeout(() => {
                    setMobileOpen(false)
                }, 200)}>
                    <ListItemText primary="Blog" />
                </ListItem>

                <ListItem onClick={() => handleNavClick(aboutRef)}>
                    <ListItemText primary="About" />
                </ListItem>

                <ListItem onClick={() => handleNavClick(contactUsRef)}>
                    <ListItemText primary="Contact" />
                </ListItem>
            </List>
        </Box>
    );

    return (
        <AppBar
            position="fixed"
            elevation={isScrolled ? 4 : 0}
            sx={() => ({
                transition: 'all 0.3s ease',
                backgroundColor: theme.palette.common.white
            })}
        >
            <Container maxWidth="lg">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={2}
                >
                    <IconButton sx={{ padding: 0 }} onClick={() => handleNavClick(homeRef)} >
                        <Logo />
                    </IconButton>

                    {isMobile ? (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => setMobileOpen(true)}
                        >
                            <MenuIcon color='action' />
                        </IconButton>
                    ) : (
                        <Box display="flex" gap={2} alignItems="center">
                            <Button onClick={() => handleNavClick(homeRef)}>
                                Home
                            </Button>

                            <Button
                                endIcon={<KeyboardArrowDownIcon />}
                                onClick={(e) => setServicesAnchorEl(e.currentTarget)}
                            >
                                Services
                            </Button>

                            <Menu
                                anchorEl={servicesAnchorEl}
                                open={Boolean(servicesAnchorEl)}
                                onClose={() => setServicesAnchorEl(null)}
                                TransitionComponent={Fade}
                            >
                                {services.map((service) => (
                                    <MenuItem
                                        key={service.path}
                                        component={Link}
                                        to={`/services/${service.path}`}
                                        onClick={() => setTimeout(() => {
                                            setServicesAnchorEl(null)
                                        }, 200)}
                                    >
                                        <Box display='block' p={2} minWidth='280px'>
                                            <Typography variant="subtitle1" color="primary">
                                                {service.title}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {service.description}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Menu>

                            <Button component={Link} to="/blogs">
                                Blog
                            </Button>

                            <Button onClick={() => handleNavClick(aboutRef)}>
                                About
                            </Button>

                            <Button onClick={() => handleNavClick(contactUsRef)}>
                                Contact
                            </Button>
                        </Box>
                    )}
                </Box>
            </Container>

            <Drawer
                anchor="left"
                open={mobileOpen}
                onClose={() => setMobileOpen(false)}
                ModalProps={{
                    keepMounted: true // Better mobile performance
                }}
            >
                {mobileDrawer}
            </Drawer>
        </AppBar>
    );
};

export default Navbar;