import { ReactNode } from 'react';
import {
  Box,
} from '@mui/material';
import { NavbarProps } from 'constants/interface';
import Navbar from './Navbar';
import Footer from './Footer';

interface LayoutProps extends NavbarProps {
  children: ReactNode
}

const Layout = ({ homeRef, aboutRef, contactUsRef, children }: LayoutProps) => (
  <Box>
    <Navbar
      homeRef={homeRef}
      aboutRef={aboutRef}
      contactUsRef={contactUsRef}
    />
    <Box>{children} </Box>
    <Footer />
  </Box>
);
export default Layout;
