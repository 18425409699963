import React from 'react';

const Logo = () => (
  <svg
    width='80%'
    height='80%'
    id='Gridlines'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 1250 300'
  >
    <g>
      <g>
        <path
          fill='#180059'
          d='M338.24,92.1h54.06v-25.49h-80.7v135.13h26.64v-52.13h52.52v-25.46h-52.52v-32.05Z'
        />
        <path
          fill='#180059'
          d='M501.17,153.49c0-14.42-4.6-26.52-13.79-36.29-9.21-9.79-20.89-14.67-35.04-14.67-14.94,0-27.15,4.85-36.68,14.57-9.53,9.72-14.3,21.84-14.3,36.39s4.82,26.84,14.5,36.48c9.65,9.65,22.45,14.47,38.42,14.47,18.51,0,32.54-6.88,42.07-20.64l-20.08-11.58c-4.87,6.44-12.1,9.65-21.62,9.65-14.79,0-23.87-6.05-27.2-18.14h72.77c.64-3.6.95-7.03.95-10.23ZM427.05,144.41c1.42-6.17,4.31-10.97,8.69-14.37,4.36-3.4,9.89-5.12,16.6-5.12,5.66,0,10.7,1.62,15.16,4.82,4.43,3.23,7.35,8.1,8.77,14.67h-49.22Z'
        />
        <path
          fill='#180059'
          d='M647.59,113.33c-6.61-7.2-15.47-10.8-26.54-10.8-12.73,0-22.33,4.63-28.75,13.88-5.68-9.26-14.42-13.88-26.27-13.88s-21.03,4.31-26.81,12.93v-10.23h-24.93v96.52h24.93v-54.24c0-7.1,1.64-12.49,4.92-16.23,3.28-3.72,7.69-5.58,13.22-5.58,5.14,0,9.13,1.59,11.95,4.82,2.84,3.21,4.26,7.71,4.26,13.52v57.71h24.9v-54.24c0-7.1,1.57-12.49,4.73-16.23,3.16-3.72,7.57-5.58,13.22-5.58,5.14,0,9.13,1.59,11.97,4.82,2.84,3.21,4.24,7.71,4.24,13.52v57.71h24.9v-59.45c0-12.1-3.31-21.77-9.94-28.97Z'
        />
        <path
          fill='#180059'
          d='M720.28,129.18v-23.95h-21.82v-27.03l-24.9,7.54v83.57c0,13.27,3.5,22.48,10.53,27.62,7,5.14,19.07,6.76,36.19,4.82v-22.6c-4.75.27-8.74.29-11.97.12-3.21-.2-5.66-1.08-7.32-2.62-1.69-1.54-2.52-3.99-2.52-7.35v-40.13h21.82Z'
        />
        <path
          fill='#180059'
          d='M812.96,117.3c-9.92-9.84-22.01-14.76-36.31-14.76s-26.35,4.92-36.19,14.76c-9.84,9.84-14.76,21.89-14.76,36.19s4.92,26.35,14.76,36.19c9.84,9.84,21.91,14.76,36.19,14.76s26.4-4.92,36.31-14.76c9.89-9.84,14.86-21.91,14.86-36.19s-4.97-26.35-14.86-36.19ZM795.38,172.59c-5.02,5.02-11.26,7.54-18.73,7.54s-13.66-2.52-18.61-7.54c-4.97-5.02-7.44-11.39-7.44-19.1s2.47-14.1,7.44-19.12c4.95-5.02,11.14-7.52,18.61-7.52s13.71,2.5,18.73,7.52c5.02,5.02,7.54,11.41,7.54,19.12s-2.52,14.08-7.54,19.1Z'
        />
        <path
          fill='#180059'
          d='M945.97,105.23l-16.41,59.45-17.58-59.45h-23.55l-17.56,59.25-16.41-59.25h-26.44l30.68,96.52h23.75l17.75-58.5,17.78,58.5h23.73l30.7-96.52h-26.44Z'
        />
        <path
          fill='#180059'
          d='M1051.18,105.23v11.39c-7.47-9.4-17.97-14.08-31.46-14.08s-23.92,4.92-33.13,14.76c-9.18,9.84-13.79,21.89-13.79,36.19s4.6,26.35,13.79,36.19c9.21,9.84,20.25,14.76,33.13,14.76s24-4.68,31.46-14.08v11.39h24.9v-96.52h-24.9ZM1043.54,173.08c-5.07,5.09-11.48,7.64-19.2,7.64s-14.1-2.55-19.12-7.64c-5.02-5.07-7.52-11.61-7.52-19.59s2.5-14.52,7.52-19.59c5.02-5.09,11.39-7.64,19.12-7.64s14.13,2.55,19.2,7.64c5.09,5.07,7.64,11.61,7.64,19.59s-2.55,14.52-7.64,19.59Z'
        />
        <path
          fill='#180059'
          d='M1130.81,107.92c-5.34,3.09-9.18,7.74-11.48,13.91v-16.6h-24.9v96.52h24.9v-46.13c0-9.28,2.99-15.89,8.96-19.88,6-3.99,12.78-5.53,20.37-4.65v-27.79c-6.56,0-12.51,1.54-17.85,4.63Z'
        />
        <path
          fill='#180059'
          d='M1250,153.49c0-14.42-4.6-26.52-13.81-36.29-9.18-9.79-20.86-14.67-35.01-14.67-14.94,0-27.18,4.85-36.68,14.57-9.52,9.72-14.3,21.84-14.3,36.39s4.82,26.84,14.47,36.48c9.67,9.65,22.48,14.47,38.42,14.47,18.54,0,32.57-6.88,42.09-20.64l-20.08-11.58c-4.9,6.44-12.1,9.65-21.62,9.65-14.79,0-23.87-6.05-27.23-18.14h72.79c.64-3.6.96-7.03.96-10.23ZM1175.88,144.41c1.4-6.17,4.31-10.97,8.69-14.37,4.36-3.4,9.89-5.12,16.6-5.12,5.66,0,10.7,1.62,15.13,4.82,4.46,3.23,7.37,8.1,8.79,14.67h-49.22Z'
        />
      </g>
      <g>
        <path
          fill='#09aa00'
          d='M144.99,0c-11.13,0-21.86,4.06-30.19,11.41l-69.01,60.86-30.21,26.43C5.67,107.38,0,119.9,0,133.07v49.39h42.22l33.16-29.23v-77.82h158.02L278.57,0h-133.58ZM57.27,164.34H18.11v-31.27c0-7.96,3.42-15.5,9.4-20.72l29.76-26.05v78.04ZM223.14,57.29H90.18l36.6-32.29c5.04-4.43,11.5-6.89,18.22-6.89h101.62l-23.47,39.18Z'
        />
        <path
          fill='#00a0f7'
          d='M144.99,107.05c-10.84,0-21.31,3.85-29.54,10.86l-40.07,35.31-33.16,29.23-28.95,25.52c-8.49,8.51-13.27,20.05-13.27,32.15v28.25h75.38v-85.92h100.74l45.17-75.4h-76.31ZM57.27,250.25H18.11v-10.14c0-7.94,3.42-15.5,9.4-20.72l29.76-26.05v56.91ZM165.87,164.34h-75.69l36.6-32.29c5.03-4.43,11.5-6.89,18.21-6.89h44.33l-23.46,39.18Z'
        />
      </g>
    </g>
    <g>
      <path
        fill='#180059'
        d='M512.08,233.52v3.65h-14.93v11.58h12.13v3.65h-12.13v15.48h-4.49v-34.36h19.42Z'
      />
      <path
        fill='#180059'
        d='M517.5,247c1.12-2.09,2.65-3.71,4.61-4.86,1.95-1.15,4.13-1.72,6.53-1.72s4.42.51,6.16,1.53c1.74,1.02,3.04,2.3,3.89,3.84v-4.93h4.53v27.01h-4.53v-5.03c-.89,1.58-2.21,2.88-3.97,3.92-1.76,1.04-3.8,1.55-6.14,1.55s-4.57-.59-6.51-1.77c-1.94-1.18-3.47-2.84-4.58-4.98-1.12-2.13-1.68-4.57-1.68-7.29s.56-5.18,1.68-7.27ZM537.46,248.99c-.82-1.51-1.93-2.67-3.33-3.47-1.4-.81-2.93-1.21-4.61-1.21s-3.2.39-4.58,1.18c-1.38.79-2.48,1.94-3.3,3.45-.82,1.51-1.23,3.29-1.23,5.32s.41,3.87,1.23,5.4c.82,1.53,1.92,2.7,3.3,3.5,1.38.81,2.91,1.21,4.58,1.21s3.21-.4,4.61-1.21c1.4-.8,2.51-1.97,3.33-3.5.82-1.53,1.23-3.31,1.23-5.35s-.41-3.81-1.23-5.32Z'
      />
      <path
        fill='#180059'
        d='M554.81,267.25c-1.64-.71-2.94-1.69-3.89-2.96-.95-1.26-1.48-2.72-1.58-4.36h4.63c.13,1.35.76,2.45,1.9,3.3s2.62,1.28,4.46,1.28c1.71,0,3.06-.38,4.04-1.13.99-.76,1.48-1.71,1.48-2.86s-.53-2.06-1.58-2.64c-1.05-.57-2.68-1.14-4.88-1.7-2-.53-3.64-1.06-4.9-1.6-1.26-.54-2.35-1.35-3.25-2.42-.9-1.07-1.36-2.47-1.36-4.21,0-1.38.41-2.64,1.23-3.79s1.99-2.06,3.5-2.74c1.51-.67,3.24-1.01,5.17-1.01,2.99,0,5.41.76,7.25,2.27,1.84,1.51,2.83,3.58,2.96,6.21h-4.48c-.1-1.41-.67-2.55-1.7-3.4-1.04-.85-2.42-1.28-4.17-1.28-1.61,0-2.89.34-3.84,1.04s-1.43,1.59-1.43,2.71c0,.89.29,1.62.86,2.19.57.58,1.3,1.04,2.17,1.38.87.35,2.08.73,3.62,1.16,1.94.53,3.52,1.04,4.73,1.55,1.21.51,2.26,1.27,3.13,2.29.87,1.02,1.32,2.35,1.36,3.99,0,1.48-.41,2.81-1.23,3.99-.82,1.18-1.98,2.11-3.48,2.79-1.5.67-3.21,1.01-5.15,1.01-2.07,0-3.93-.35-5.57-1.06Z'
      />
      <path
        fill='#180059'
        d='M582.02,244.56v15.92c0,1.31.28,2.24.84,2.79.56.54,1.53.81,2.91.81h3.3v3.79h-4.04c-2.5,0-4.37-.57-5.62-1.73-1.25-1.15-1.87-3.04-1.87-5.67v-15.92h-3.5v-3.7h3.5v-6.8h4.49v6.8h7.05v3.7h-7.05Z'
      />
      <path
        fill='#180059'
        d='M618.99,256.04h-21.59c.16,2.66,1.08,4.74,2.74,6.23,1.66,1.5,3.67,2.24,6.04,2.24,1.94,0,3.56-.45,4.85-1.36,1.3-.9,2.21-2.11,2.74-3.62h4.83c-.72,2.6-2.17,4.71-4.34,6.33-2.17,1.63-4.86,2.44-8.08,2.44-2.56,0-4.85-.57-6.88-1.72s-3.61-2.79-4.76-4.9c-1.15-2.12-1.73-4.58-1.73-7.37s.56-5.24,1.68-7.34c1.12-2.1,2.69-3.72,4.71-4.86s4.34-1.7,6.97-1.7,4.83.56,6.8,1.68c1.97,1.12,3.49,2.65,4.56,4.61,1.07,1.96,1.6,4.17,1.6,6.63,0,.85-.05,1.76-.15,2.71ZM613.37,247.98c-.76-1.23-1.78-2.17-3.08-2.81-1.3-.64-2.74-.96-4.31-.96-2.27,0-4.2.72-5.79,2.17-1.59,1.45-2.51,3.45-2.73,6.01h17.05c0-1.71-.38-3.18-1.13-4.41Z'
      />
      <path
        fill='#180059'
        d='M632.91,241.65c1.46-.85,3.24-1.28,5.35-1.28v4.63h-1.18c-5.03,0-7.54,2.73-7.54,8.18v14.69h-4.49v-27.01h4.49v4.39c.79-1.54,1.91-2.74,3.38-3.6Z'
      />
      <path
        fill='#180059'
        d='M642.69,267.28c-.59-.59-.89-1.31-.89-2.17s.3-1.58.89-2.17,1.31-.89,2.17-.89,1.52.3,2.09.89c.58.59.86,1.31.86,2.17s-.29,1.58-.86,2.17c-.58.59-1.27.89-2.09.89s-1.58-.3-2.17-.89Z'
      />
      <path
        fill='#180059'
        d='M671.75,267.01c-1.79-.8-3.2-1.92-4.21-3.35-1.02-1.43-1.54-3.08-1.58-4.95h4.78c.16,1.61.83,2.97,2,4.07,1.17,1.1,2.87,1.65,5.1,1.65s3.82-.53,5.05-1.6c1.23-1.07,1.85-2.44,1.85-4.12,0-1.31-.36-2.38-1.08-3.2-.72-.82-1.63-1.45-2.71-1.87-1.08-.43-2.55-.89-4.39-1.38-2.27-.59-4.08-1.18-5.45-1.77s-2.53-1.52-3.5-2.79c-.97-1.26-1.45-2.96-1.45-5.1,0-1.87.48-3.53,1.43-4.98.95-1.45,2.29-2.56,4.02-3.35,1.73-.79,3.7-1.18,5.94-1.18,3.22,0,5.86.81,7.91,2.42,2.05,1.61,3.21,3.75,3.47,6.41h-4.93c-.17-1.31-.85-2.47-2.07-3.48-1.22-1-2.83-1.5-4.83-1.5-1.87,0-3.4.49-4.58,1.45-1.18.97-1.77,2.33-1.77,4.07,0,1.25.35,2.27,1.06,3.06.71.79,1.58,1.39,2.61,1.8s2.49.88,4.36,1.4c2.27.62,4.09,1.24,5.47,1.85,1.38.61,2.56,1.54,3.55,2.81.99,1.26,1.48,2.98,1.48,5.15,0,1.68-.44,3.25-1.33,4.73-.89,1.48-2.2,2.68-3.94,3.6-1.74.92-3.79,1.38-6.16,1.38s-4.3-.4-6.09-1.21Z'
      />
      <path
        fill='#180059'
        d='M733.98,241.67c1.64.87,2.94,2.18,3.89,3.92.95,1.74,1.43,3.86,1.43,6.36v15.92h-4.44v-15.28c0-2.69-.67-4.76-2-6.19-1.33-1.43-3.13-2.14-5.4-2.14s-4.19.75-5.57,2.24c-1.38,1.5-2.07,3.66-2.07,6.48v14.89h-4.44v-15.28c0-2.69-.67-4.76-2-6.19-1.33-1.43-3.13-2.14-5.4-2.14s-4.19.75-5.57,2.24c-1.38,1.5-2.07,3.66-2.07,6.48v14.89h-4.49v-27.01h4.49v3.89c.89-1.41,2.08-2.5,3.57-3.25,1.49-.76,3.15-1.13,4.95-1.13,2.27,0,4.27.51,6.01,1.53,1.74,1.02,3.04,2.51,3.89,4.49.76-1.91,2-3.38,3.75-4.44,1.74-1.05,3.68-1.58,5.82-1.58s3.98.44,5.62,1.31Z'
      />
      <path
        fill='#180059'
        d='M746.64,247c1.12-2.09,2.65-3.71,4.61-4.86,1.95-1.15,4.13-1.72,6.53-1.72s4.42.51,6.16,1.53c1.74,1.02,3.04,2.3,3.89,3.84v-4.93h4.53v27.01h-4.53v-5.03c-.89,1.58-2.21,2.88-3.97,3.92-1.76,1.04-3.8,1.55-6.14,1.55s-4.57-.59-6.51-1.77c-1.94-1.18-3.47-2.84-4.58-4.98-1.12-2.13-1.68-4.57-1.68-7.29s.56-5.18,1.68-7.27ZM766.61,248.99c-.82-1.51-1.93-2.67-3.33-3.47-1.4-.81-2.93-1.21-4.61-1.21s-3.2.39-4.58,1.18c-1.38.79-2.48,1.94-3.3,3.45-.82,1.51-1.23,3.29-1.23,5.32s.41,3.87,1.23,5.4c.82,1.53,1.92,2.7,3.3,3.5,1.38.81,2.91,1.21,4.58,1.21s3.21-.4,4.61-1.21c1.4-.8,2.5-1.97,3.33-3.5.82-1.53,1.23-3.31,1.23-5.35s-.41-3.81-1.23-5.32Z'
      />
      <path
        fill='#180059'
        d='M787.82,241.65c1.46-.85,3.24-1.28,5.35-1.28v4.63h-1.18c-5.03,0-7.54,2.73-7.54,8.18v14.69h-4.49v-27.01h4.49v4.39c.79-1.54,1.91-2.74,3.38-3.6Z'
      />
      <path
        fill='#180059'
        d='M803.82,244.56v15.92c0,1.31.28,2.24.84,2.79.56.54,1.53.81,2.91.81h3.3v3.79h-4.04c-2.5,0-4.37-.57-5.62-1.73-1.25-1.15-1.87-3.04-1.87-5.67v-15.92h-3.5v-3.7h3.5v-6.8h4.49v6.8h7.05v3.7h-7.05Z'
      />
      <path
        fill='#180059'
        d='M840.79,256.04h-21.59c.16,2.66,1.08,4.74,2.74,6.23,1.66,1.5,3.67,2.24,6.04,2.24,1.94,0,3.56-.45,4.86-1.36,1.3-.9,2.21-2.11,2.74-3.62h4.83c-.72,2.6-2.17,4.71-4.34,6.33-2.17,1.63-4.86,2.44-8.08,2.44-2.56,0-4.85-.57-6.88-1.72-2.02-1.15-3.61-2.79-4.76-4.9-1.15-2.12-1.73-4.58-1.73-7.37s.56-5.24,1.68-7.34,2.69-3.72,4.71-4.86c2.02-1.13,4.34-1.7,6.97-1.7s4.83.56,6.8,1.68,3.49,2.65,4.56,4.61c1.07,1.96,1.6,4.17,1.6,6.63,0,.85-.05,1.76-.15,2.71ZM835.17,247.98c-.76-1.23-1.78-2.17-3.08-2.81-1.3-.64-2.74-.96-4.31-.96-2.27,0-4.2.72-5.79,2.17-1.59,1.45-2.51,3.45-2.74,6.01h17.05c0-1.71-.38-3.18-1.13-4.41Z'
      />
      <path
        fill='#180059'
        d='M854.71,241.65c1.46-.85,3.24-1.28,5.35-1.28v4.63h-1.18c-5.03,0-7.54,2.73-7.54,8.18v14.69h-4.49v-27.01h4.49v4.39c.79-1.54,1.91-2.74,3.38-3.6Z'
      />
      <path
        fill='#180059'
        d='M864.49,267.28c-.59-.59-.89-1.31-.89-2.17s.3-1.58.89-2.17,1.31-.89,2.17-.89,1.52.3,2.09.89c.58.59.86,1.31.86,2.17s-.29,1.58-.86,2.17c-.58.59-1.27.89-2.09.89s-1.58-.3-2.17-.89Z'
      />
      <path
        fill='#180059'
        d='M908.78,250.69c-1.96,1.89-4.94,2.83-8.95,2.83h-6.61v14.34h-4.49v-34.36h11.09c3.88,0,6.83.94,8.85,2.81,2.02,1.87,3.03,4.29,3.03,7.25s-.98,5.23-2.93,7.12ZM905.36,248.2c1.18-1.08,1.77-2.63,1.77-4.63,0-4.24-2.43-6.36-7.29-6.36h-6.61v12.62h6.61c2.5,0,4.34-.54,5.52-1.63Z'
      />
      <path
        fill='#180059'
        d='M925.14,241.65c1.46-.85,3.24-1.28,5.35-1.28v4.63h-1.18c-5.03,0-7.54,2.73-7.54,8.18v14.69h-4.49v-27.01h4.49v4.39c.79-1.54,1.91-2.74,3.38-3.6Z'
      />
      <path
        fill='#180059'
        d='M960.16,256.04h-21.59c.16,2.66,1.08,4.74,2.74,6.23,1.66,1.5,3.67,2.24,6.04,2.24,1.94,0,3.56-.45,4.86-1.36,1.3-.9,2.21-2.11,2.74-3.62h4.83c-.72,2.6-2.17,4.71-4.34,6.33-2.17,1.63-4.86,2.44-8.08,2.44-2.56,0-4.85-.57-6.88-1.72-2.02-1.15-3.61-2.79-4.76-4.9-1.15-2.12-1.73-4.58-1.73-7.37s.56-5.24,1.68-7.34,2.69-3.72,4.71-4.86c2.02-1.13,4.34-1.7,6.97-1.7s4.83.56,6.8,1.68,3.49,2.65,4.56,4.61c1.07,1.96,1.6,4.17,1.6,6.63,0,.85-.05,1.76-.15,2.71ZM954.55,247.98c-.76-1.23-1.78-2.17-3.08-2.81-1.3-.64-2.74-.96-4.31-.96-2.27,0-4.2.72-5.79,2.17-1.59,1.45-2.51,3.45-2.74,6.01h17.05c0-1.71-.38-3.18-1.13-4.41Z'
      />
      <path
        fill='#180059'
        d='M966.23,247c1.12-2.09,2.67-3.71,4.66-4.86,1.99-1.15,4.26-1.72,6.83-1.72,3.32,0,6.05.81,8.21,2.42,2.15,1.61,3.57,3.84,4.26,6.7h-4.83c-.46-1.64-1.36-2.94-2.69-3.89s-2.98-1.43-4.95-1.43c-2.56,0-4.63.88-6.21,2.64-1.58,1.76-2.37,4.25-2.37,7.47s.79,5.77,2.37,7.54,3.65,2.66,6.21,2.66c1.97,0,3.61-.46,4.93-1.38,1.31-.92,2.22-2.23,2.71-3.94h4.83c-.72,2.76-2.17,4.97-4.34,6.63-2.17,1.66-4.88,2.49-8.13,2.49-2.56,0-4.84-.57-6.83-1.72s-3.54-2.78-4.66-4.88c-1.12-2.1-1.68-4.57-1.68-7.39s.56-5.23,1.68-7.32Z'
      />
      <path
        fill='#180059'
        d='M996.29,235.59c-.59-.59-.89-1.31-.89-2.17s.3-1.58.89-2.17c.59-.59,1.31-.89,2.17-.89s1.52.3,2.09.89c.57.59.86,1.31.86,2.17s-.29,1.58-.86,2.17c-.58.59-1.27.89-2.09.89s-1.58-.3-2.17-.89ZM1000.63,240.86v27.01h-4.49v-27.01h4.49Z'
      />
      <path
        fill='#180059'
        d='M1012.26,267.25c-1.64-.71-2.94-1.69-3.89-2.96s-1.48-2.72-1.58-4.36h4.63c.13,1.35.76,2.45,1.9,3.3s2.62,1.28,4.46,1.28c1.71,0,3.06-.38,4.04-1.13.99-.76,1.48-1.71,1.48-2.86s-.53-2.06-1.58-2.64c-1.05-.57-2.68-1.14-4.88-1.7-2-.53-3.64-1.06-4.9-1.6-1.27-.54-2.35-1.35-3.25-2.42-.9-1.07-1.36-2.47-1.36-4.21,0-1.38.41-2.64,1.23-3.79s1.99-2.06,3.5-2.74c1.51-.67,3.24-1.01,5.17-1.01,2.99,0,5.41.76,7.25,2.27,1.84,1.51,2.83,3.58,2.96,6.21h-4.49c-.1-1.41-.67-2.55-1.7-3.4-1.04-.85-2.42-1.28-4.17-1.28-1.61,0-2.89.34-3.84,1.04s-1.43,1.59-1.43,2.71c0,.89.29,1.62.86,2.19.57.58,1.3,1.04,2.17,1.38.87.35,2.08.73,3.62,1.16,1.94.53,3.52,1.04,4.73,1.55,1.21.51,2.26,1.27,3.13,2.29.87,1.02,1.32,2.35,1.36,3.99,0,1.48-.41,2.81-1.23,3.99-.82,1.18-1.98,2.11-3.47,2.79-1.5.67-3.21,1.01-5.15,1.01-2.07,0-3.93-.35-5.57-1.06Z'
      />
      <path
        fill='#180059'
        d='M1058.49,256.04h-21.59c.16,2.66,1.08,4.74,2.74,6.23,1.66,1.5,3.67,2.24,6.04,2.24,1.94,0,3.56-.45,4.86-1.36,1.3-.9,2.21-2.11,2.74-3.62h4.83c-.72,2.6-2.17,4.71-4.34,6.33-2.17,1.63-4.86,2.44-8.08,2.44-2.56,0-4.85-.57-6.88-1.72-2.02-1.15-3.61-2.79-4.76-4.9-1.15-2.12-1.73-4.58-1.73-7.37s.56-5.24,1.68-7.34,2.69-3.72,4.71-4.86c2.02-1.13,4.34-1.7,6.97-1.7s4.83.56,6.8,1.68,3.49,2.65,4.56,4.61c1.07,1.96,1.6,4.17,1.6,6.63,0,.85-.05,1.76-.15,2.71ZM1052.88,247.98c-.76-1.23-1.78-2.17-3.08-2.81-1.3-.64-2.74-.96-4.31-.96-2.27,0-4.2.72-5.79,2.17-1.59,1.45-2.51,3.45-2.74,6.01h17.05c0-1.71-.38-3.18-1.13-4.41Z'
      />
      <path
        fill='#180059'
        d='M1063.82,267.28c-.59-.59-.89-1.31-.89-2.17s.3-1.58.89-2.17,1.31-.89,2.17-.89,1.52.3,2.09.89c.58.59.86,1.31.86,2.17s-.29,1.58-.86,2.17c-.58.59-1.27.89-2.09.89s-1.58-.3-2.17-.89Z'
      />
    </g>
  </svg>
);

export default Logo;
