import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from 'react-scroll-to-top';
import Layout from 'components/Layout';
import { routes } from 'routes';

const App = () => {
	const homeRef = useRef<HTMLDivElement>(null);
	const aboutRef = useRef<HTMLDivElement>(null);
	const contactUsRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		AOS.init({
			offset: 0,
			easing: 'ease',
			once: true,
		});
		AOS.refresh();
	}, []);

	return (
		<Router>
			<React.Suspense fallback={<div>Loading...</div>}>
				<Routes>
					<Route element={<Layout homeRef={homeRef} aboutRef={aboutRef} contactUsRef={contactUsRef}><Outlet /></Layout>}>
						{routes.map((route) => (
							<Route key={route.path} path={route.path} element={route.path === '/' ? <route.element homeRef={homeRef}
								aboutRef={aboutRef}
								contactUsRef={contactUsRef} /> : <route.element />} />
						))}
					</Route>
					<Route path="*" element={<div>Page Not Found</div>} />
				</Routes>
			</React.Suspense>
			<ScrollToTop
				smooth
				color="#246BFD"
			/>
		</Router>
	);
}

export default App;
