export const COLORS = {
  primary: '#00a0f7',
  secondary: '#9c27b0',
  sky: '#00bcd4',
  background: '#ffffff',
  text: '#000000',
};

export const SPACING = {
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
};

export const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};
