import { FC, lazy, LazyExoticComponent } from 'react';

const Home = lazy(() => import('../Home'));

const WebDevelopment = lazy(() => import('../pages/services/WebDevelopment'));
const MobileDevelopment = lazy(() => import('../pages/services/MobileDevelopment'));
const CustomSoftware = lazy(() => import('../pages/services/CustomSoftware'));
const CloudSolutions = lazy(() => import('pages/services/CloudSolutions'));
const AIMachineLearning = lazy(() => import('pages/services/AIMachineLearning'));

const AIConsulting = lazy(() => import('components/Solutions/AIConsulting'));
const AIDevelopment = lazy(() => import('components/Solutions/AIDevelopment'));
const AIIntegration = lazy(() => import('components/Solutions/AIIntegration'));
const GenerativeAI = lazy(() => import('components/Solutions/GenerativeAI'));
const AIAutomation = lazy(() => import('components/Solutions/AIAutomation'));

const Blogs = lazy(() => import('../pages/Blogs/Blogs'));
const Experience = lazy(() => import('../pages/Experience/Experience'));

interface RouteConfig {
  path: string;
  element: LazyExoticComponent<FC<any>>;
}

export const routes: RouteConfig[] = [
  {
    path: '/',
    element: Home,
  },
  {
    path: 'services/web-development',
    element: WebDevelopment,
  },
  {
    path: 'services/mobile-development',
    element: MobileDevelopment,
  },
  {
    path: 'services/custom-software',
    element: CustomSoftware,
  },
  {
    path: 'services/cloud-solutions',
    element: CloudSolutions,
  },
  {
    path: 'services/ai-ml',
    element: AIMachineLearning,
  },
  {
    path: 'solutions/consulting',
    element: AIConsulting,
  },
  {
    path: 'solutions/development',
    element: AIDevelopment,
  },
  {
    path: 'solutions/integration',
    element: AIIntegration,
  },
  {
    path: 'solutions/generative-ai',
    element: GenerativeAI,
  },
  {
    path: 'solutions/automation',
    element: AIAutomation,
  },
  {
    path: 'blogs',
    element: Blogs,
  },
  {path: 'experience',
    element: Experience,
  },
];
