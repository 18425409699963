import React, { FormEvent, useRef } from 'react';
import {
  FaEnvelope,
  FaMapMarkerAlt,
  // FaRegCalendarAlt,
  FaTwitter,
  // FaYoutube,
  FaLinkedin,
} from 'react-icons/fa';
// import { Link, useLocation } from 'react-router-dom';
import emailjs from '@emailjs/browser';
// import { useQuery } from '@tanstack/react-query';
// import axios, { AxiosResponse } from 'axios';
// import { Blogs } from './interface';
// import { formatDate } from './dayjs';

const Footer = () => {
  const form = useRef<HTMLFormElement>(null);
  // const location = useLocation()

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID!,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID!,
      form.current!,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY!,
    );
  };

  // const { data: postsList } = useQuery<AxiosResponse<any, any>, any, Blogs[], string[]>({
  // 	queryKey: ['posts_list'],
  // 	queryFn: () => axios.get('/posts'),
  // 	cacheTime: 0,
  // 	select: ({ data }) => data,
  // });

  // const recentBlogs = postsList && postsList?.length === 1 ? postsList : postsList?.splice(-2)

  return (
    <footer
      className='footer-area bg-cover pd-top-120'
      style={{ backgroundImage: 'url("assets/img/bg/14.png")' }}
    >
      <div className='footer-subscribe'>
        <div className='container'>
          <div className='footer-subscribe-inner box-shadow style-2 bg-white p-0 bg-cover'>
            <form className='row align-self-center' onSubmit={onSubmit} ref={form}>
              <div className='col-lg-5'>
                <div className='border-1'>
                  <input required name='name' placeholder='Your Name' />
                </div>
              </div>
              <div className='col-lg-5'>
                <div className='border-1'>
                  <input required name='email' type='email' placeholder='Your Email' />
                </div>
              </div>
              <div className='col-lg-2'>
                <button type='submit' className='btn w-100 btn-black sky border-radius-0'>
                  Submit now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 col-md-6'>
            <div className='widget widget-recent-post'>
              <h4 className='widget-title'>Contact us</h4>
              <div className='widget widget_contact'>
                <ul className='details text-white'>
                  <li>
                    <FaMapMarkerAlt className='sky' />
                    NO.268,15 WARD, KARADKA POST, KASARAGOD, KERALA, 671542
                  </li>
                  <li>
                    <FaMapMarkerAlt className='sky' />
                    237, 2nd Floor, BCMC Layout, Bangalore - 560062
                  </li>
                  <li className='mt-2'>
                    <FaEnvelope className='sky' />
                    <a href='mailto:contactus@fwinfotech.com'>contactus@fwinfotech.com</a>
                  </li>
                </ul>
                <ul className='social-media mt-4'>
                  <li>
                    <a
                      href='https://x.com/Femtoware322'
                      className='sky'
                      aria-label='Twitter profile'
                    >
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.linkedin.com/in/femtoware-infotech-llp-898447230/'
                      className='sky'
                      aria-label='Linkedin Profile'
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                  {/* <li>
										<a
											href=""
											className='sky'
										>
											<FaYoutube />
										</a>
									</li> */}
                </ul>
              </div>
            </div>
          </div>
          {/* {!location.pathname.includes('blog') &&
						<div className='col-lg-6 col-md-6'>
							<div className='widget widget-recent-post'>
								<h4 className='widget-title'>Recent Blog</h4>
								<ul>
									{recentBlogs?.map((post) => (
										<li key={post.id} className='sky'>
											<div className='media'>
												<div className='media-body align-self-center'>
													<div className='post-info  mb-2'>
														<FaRegCalendarAlt className='sky' />
														<span>{formatDate(post.updated, 'MMMM DD, YYYY')}</span>
													</div>
													<h6 className='title sky mb-0'>
														<Link to={`blog/${post.blog.id}/${post.id}`}>
															{post.title}
														</Link>
													</h6>
												</div>
											</div>
										</li>
									))}
								</ul>
							</div>
						</div>} */}
        </div>
      </div>
      <div className='footer-bottom'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 align-self-center'>
              <p>© Femtoware Infotech LLP | All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
