import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactElement, ReactNode } from 'react';
import { COLORS, BREAKPOINTS } from '../styles/theme/constants';

declare module '@mui/material/styles' {
  interface Palette {
    sky: Palette['primary'];
  }
  interface PaletteOptions {
    sky?: PaletteOptions['primary'];
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
      light: '#42a5f5',
      dark: '#1565c0',
    },
    sky: {
      main: COLORS.sky,
      light: '#4dd0e1',
      dark: '#0097a7',
    },
    secondary: {
      main: COLORS.secondary,
      light: '#ba68c8',
      dark: '#7b1fa2',
    },
  },
  breakpoints: {
    values: BREAKPOINTS,
  },
  typography: {
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      styleOverrides: {
        root: () => ({
            '&:hover': {
              backgroundColor: 'transparent',
            },
        }),
      },
    },
    /**
     * Overrides the default styles for the MuiIconButton component.
     * @returns An object containing the style overrides for the MuiIconButton component.
     */
    MuiIconButton: {
      styleOverrides: {
        root: () => ({
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'unset',
            backgroundColor: 'transparent',
          },
        }),
      },
    },
  },
});

/**
 * A custom Material UI theme provider that can be used to override the default theme.
 * @param {ReactNode} props - The props to pass to the theme provider.
 * @returns {ReactElement} - The custom theme provider.
 */
export const CustomMuiThemeProvider = ({ children }: { children: ReactNode }): ReactElement => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);