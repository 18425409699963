import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import './index.scss';
import * as theme from './theme'

const { CustomMuiThemeProvider } = theme;

const root = ReactDOM.createRoot(document.getElementById('root'));

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

root.render(
  <StrictMode>
    <CustomMuiThemeProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </CustomMuiThemeProvider>
  </StrictMode>,
);
